// @flow strict
import React from 'react';

import Layout from '../components/Layout';
import Page from '../components/Page';
import { useSiteMetadata } from '../hooks';
import SimpleLayout from '../components/simpleLayout';

const NotFoundTemplate = () => {
  const { title, subtitle } = useSiteMetadata();

  return (
    <SimpleLayout>
    <Layout title={`Not Found - ${title}`} description={subtitle}>
     
      <Page title="NOT FOUND">
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Page>
    </Layout>
    </SimpleLayout>
  );
};

export default NotFoundTemplate;
